import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./unsubscribed.scss"

const UnsubscribedPage = () => (
  <Layout>
    <SEO lang="en" title="Unsubscribed" />
    <section className="unsubscribed--container">
      <h1 className="fg-black-5">Unsubscribed</h1>
      <p className="fg-black-3">
        You are now unsubscribed from our newsletter.
        <br />
        If you have any further questions, please do not hesitate to get in
        contact.
      </p>
    </section>
  </Layout>
)

export default UnsubscribedPage
